import Vue from 'vue';
import pinia from './loadPinia.js'

// @ts-ignore
import App from './App.vue'
import store from './store/index.js'
import router from './router'
import './quasar.js'
import { HighContrastService } from '@parantion/components'

const vueInstance = new Vue({
  store,
  pinia,
  router,
  render: h => h(App)
}).$mount('#app')

// @ts-ignore
vueInstance.$initTenant()

// @ts-ignore
window.__Tenant_Vue = vueInstance

HighContrastService.init()

// @ts-ignore
// window._set_base_url('https://pentest.easion.nl/backend/api')

// function sendError (error, message) {
//   store.dispatch('parantion/error/registerError', {
//     message: message,
//     errorTrace: error.response,
//     payload: store.state.survey.activeSurvey,
//     location: location.href,
//     name: packageJSON.name,
//     version: packageJSON.version
//   })
// }
//
// window.onerror = function (message, errorTrace) {
//   console.log('%cERROR: ', 'color: red', message, errorTrace)
//   sendError(message, errorTrace)
// }
//
// axios.interceptors.response.use(undefined, function (error) {
//   console.log('%cERROR - axios: ', 'color: red', error)
//   const errorData = error.response
//   const message = errorData.status + ' - ' + errorData.statusText + ' - ' + errorData.data.message
//   sendError(error, message)
//   return Promise.reject(error)
// })
