import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import packageJson from '../../package'

Vue.use(Vuex)
const store = new Vuex.Store({
  plugins: [createPersistedState({
    storage: window.sessionStorage,
    key: packageJson.name + '-vx'
  })]
})
export default store
