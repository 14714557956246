import Vue from 'vue'
// import './styles/quasar.styl'
import 'quasar/dist/quasar.ie.polyfills'
import 'quasar/dist/quasar.min.css'
import '@quasar/extras/mdi-v3/mdi-v3.css'
import '@quasar/extras/material-icons/material-icons.css'

import '@mdi/font/css/materialdesignicons.css'
import './themes/easion.styl'
import './themes/app.styl'
import Vuelidate from 'vuelidate'
import router from './router'
// @ts-ignore
import packageJSON from '../package.json'
import {
  ClosePopup,
  Dialog,
  Notify,
  Platform,
  QBtn,
  QBtnDropdown,
  QCard,
  QCardActions,
  QCardSection,
  QCheckbox,
  QChip,
  QDate,
  QDialog,
  QExpansionItem,
  QFab,
  QFabAction,
  QField,
  QIcon,
  QInput,
  QItem,
  QItemLabel,
  QItemSection,
  QLayout,
  QLinearProgress,
  QList,
  QMenu,
  QRadio,
  QSelect,
  QSeparator,
  QSpinnerFacebook,
  QSpinnerGears,
  QSpinnerHourglass,
  QSpinnerRadio,
  QTime,
  QToggle,
  QToolbar,
  QToolbarTitle,
  QTooltip,
  Quasar,
  Ripple,
  Screen
} from 'quasar'
import {
  DataModule,
  ErrorModule,
  ItemsModule,
  JobsModule,
  Moustache,
  NewFilesModule,
  Parantion,
  TemplateModule,
  Toaster,
  TranslationManager,
  UserModule
} from '@parantion/components'
import tenantTranslation from './assets/tenant_translation.yaml'
import axios from 'axios'
import store from './store'
// import '@quasar/extras/mdi-v3'
// import '@quasar/extras/fontawesome-v5'

Vue.use(Vuelidate)
// @ts-ignore
Vue.use(Quasar, {
  plugins: { Notify, Dialog, Screen, Platform },
  components: {
    QExpansionItem,
    QCard,
    QChip,
    QCheckbox,
    QLayout,
    QSelect,
    QRadio,
    QField,
    QFab,
    QBtnDropdown,
    QFabAction,
    QInput,
    QSeparator,
    QMenu,
    QDialog,
    QCardSection,
    QCardActions,
    QDate,
    QTime,
    QToolbar,
    QToggle,
    QToolbarTitle,
    QTooltip,
    QBtn,
    QIcon,
    QSpinnerRadio,
    QSpinnerHourglass,
    QSpinnerFacebook,
    QSpinnerGears,
    QList,
    QItem,
    QItemLabel,
    QItemSection,
    QLinearProgress
  },
  directives: { ClosePopup, Ripple }
})

let env = window.location.href.match(/\..*?\./)
const containsLocalhost = /localhost/.test(window.location.href)
const containsIp = containsIpAddress(window.location.href)
let status = 'live'

function containsIpAddress (url) {
  return /\/\/\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}:/.test(url)
}

if (containsLocalhost || containsIp) {
  status = 'dev'
} else if (env) {
  // @ts-ignore
  env = env[0].replace(/\./g, '')
  // @ts-ignore
  if (['dev', 'test'].includes(env)) {
    // @ts-ignore
    Vue.config.devtools = true
  }
}

Vue.use(Toaster, Notify)
Vue.use(Moustache)
Vue.use(TranslationManager, { tenant: 'formviewer', locale: 'nl' }, tenantTranslation) // Install Translation Manager
Vue.config.productionTip = false

/* PLUGINS */
Vue.prototype.$axios = axios

Vue.use(Parantion, {
  store,
  router,
  packageJSON,
  modules: { UserModule, JobsModule, TemplateModule, ErrorModule, NewFilesModule, ItemsModule, DataModule },
  domain: 'parantion.nl',
  useLockScreen: false,
  autoLockscreen: false
})

// Vue.use(Quasar, {
//   config: {},
//   components: { /* not needed if importStrategy is not 'manual' */ },
//   directives: { /* not needed if importStrategy is not 'manual' */ },
//   plugins: {
//   },
//   lang: lang
//  })

if (window.location.host === 'lifelines.beta.easion.nl') {
  store.commit('parantion/SET_BASEURL', 'https://lifelines.beta.easion.nl/backend/api')
}
