import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'Layout',
      props: true,
      meta: {
        requiresAuth: true // Authentication works for children as well
      },
      component: () => import('./components/Home.vue'),
      children: [{
        path: '/home',
        name: 'home',
        component: () => import('./components/Home.vue')
      }]
    }]
})
